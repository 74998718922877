import React, { useEffect, useState } from "react";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { Navigate, useLocation } from "react-router-dom";
import auth from "../firebase.init";
import { toast } from "react-toastify";
import MainLoader from "../components/Loader/MainLoader";
import { useGlobalStore } from "../store/GlobalStoreContext";
import { FETCH_CUSTOMERS, FETCH_USERS } from "../store/actionTypes";

const RequireAuth = ({ children }) => {
  const { apiUrl } = useGlobalStore();
  const [user, loading] = useAuthState(auth);
  const [signOut, signOutLoading, error] = useSignOut(auth);
  const [fetchLoading, setFetchLoading] = useState(false);
  let location = useLocation();
  useEffect(() => {
    if (user?.email) {
      setFetchLoading(true);
      fetch(`${apiUrl}/api/v2/users/${user.email}/email`).then((res) =>
        res.json().then((data) => {
          if (data.success) {
            const result = data?.data;
            if (result.email === user.email) {
              setFetchLoading(false);
            } else {
              signOut();
              setFetchLoading(false);
              toast.error("Unauthorized Access !!!!");
            }
          } else {
            signOut();
            localStorage.removeItem("accessToken");
          }
        })
      );
    }
  }, [user]);

  if (loading || signOutLoading || fetchLoading) {
    return <MainLoader />;
  }
  if (!user) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }
  return children;
};

export default RequireAuth;
