import React from "react";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { FiLogOut } from "react-icons/fi";

const DropdownUser = () => {
  const [googleUser, googleLoading, googleError] = useAuthState(auth);
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const [signOut, loading, error] = useSignOut(auth);
  const handleSignOut = () => {
    localStorage.removeItem("accessToken");
    signOut();
  };
  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          setDropdownPopoverShow(!dropdownPopoverShow);
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <img
              alt="..."
              className="w-full rounded-full align-middle border-none shadow-lg"
              src={googleUser?.photoURL}
            />
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 absolute top-[20px] left-0 translate-x-[-80%] translate-y-[20%]  py-2 list-none text-center rounded shadow-lg min-w-48 mb-2 px-5"
        }
      >
        <p
          className={
            "text-sm py-2 px-4 font-semibold block w-full whitespace-nowrap bg-transparent text-black"
          }
        >
          {googleUser?.displayName}
        </p>
        <p
          className={
            "text-sm py-2 px-4 font-medium block w-full whitespace-nowrap bg-transparent text-slate-700"
          }
        >
          {googleUser?.email}
        </p>
        <div className="h-0 my-2 border border-solid border-blueGray-100" />
        <div className="flex justify-center py-2">
          <button
            onClick={() => handleSignOut()}
            className="px-8 py-1 bg-tertiary text-lg flex items-center rounded font-semibold"
          >
            <FiLogOut className="fas fa-paint-brush mr-2 text-blueGray-300 text-base" />
            Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default DropdownUser;
