import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useModal from "../../hooks/useModal";

// components

import DropdownTableCustomer from "../Dropdowns/DropdownTableCustomer";
import ModalAddCustomer from "../Modals/ModalAddCustomer";
import TableLoader from "../Loader/TableLoader";
import ButtonRefresh from "../Buttons/ButtonRefresh";
import { FaCircle, FaUser } from "react-icons/fa";
import { useGlobalStore } from "../../store/GlobalStoreContext";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import {
  FETCH_CUSTOMERS,
  SET_ERROR,
  SET_LOADING,
} from "../../store/actionTypes";
import CardTableError from "./CardTableError";

export default function CardTableCustomers({ color }) {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [googleUser, googleLoading, googleRrror] = useAuthState(auth);
  const { modalIsOpen, openModal, closeModal } = useModal(false);

  const fetchData = async (url) => {
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await response.json();
      if (data.success) {
        setCustomers(data.data);
      } else {
        setError(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleRefresh = async () => {
    fetchData("https://api.reefalbustan.com/api/v2/customers");
  };
  useEffect(() => {
    fetchData("https://api.reefalbustan.com/api/v2/customers");
  }, []);

  if (loading) {
    return <TableLoader />;
  }
  if (error) {
    return <CardTableError error={customers.error} />;
  }

  let content = (
    <>
      {customers
        ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .map((customer) => (
          <tr key={customer.id} className="font-semibold border-b">
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <div className="flex items-center gap-x-3">
                <FaUser className="text-lg" />
                <span>{customer.fullName}</span>
              </div>
            </td>
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {customer.phoneNo}
            </td>
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {customer.email}
            </td>
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {customer.address}
            </td>
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {/* {customer.ordersId.length} */}
            </td>

            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right relative">
              <DropdownTableCustomer
                customers={customers}
                setCustomers={setCustomers}
                handleRefresh={handleRefresh}
                customer={customer}
                googleUser={googleUser}
              />
            </td>
          </tr>
        ))}
    </>
  );
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                Customers
              </h3>
            </div>
            <div>
              <ButtonRefresh onClick={() => handleRefresh()} />
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Name
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Phone
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Email
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Address
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Orders
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th>
              </tr>
            </thead>
            <tbody>{customers?.loading ? <TableLoader /> : content}</tbody>
          </table>
        </div>
      </div>
      <ModalAddCustomer isOpen={modalIsOpen} closeModal={closeModal} />
    </>
  );
}

CardTableCustomers.defaultProps = {
  color: "light",
};

CardTableCustomers.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
