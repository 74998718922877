import React, { useEffect, useState } from "react";
import ButtonRefresh from "../../components/Buttons/ButtonRefresh";
import CardPartner from "../../components/Cards/CardPartner";
import ShowcaseLoader from "../../components/Loader/ShowcaseLoader";
import CardTableError from "../../components/Cards/CardTableError";
import { useGlobalStore } from "../../store/GlobalStoreContext";

const Partners = () => {
  const { apiUrl } = useGlobalStore();
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async (url) => {
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await response.json();
      if (data.success) {
        setPartners(data.data);
      } else {
        setError(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };
  const handleRefresh = async () => {
    fetchData(`${apiUrl}/api/v2/partners`);
  };
  useEffect(() => {
    fetchData(`${apiUrl}/api/v2/partners`);
  }, []);
  if (loading) {
    return <ShowcaseLoader />;
  }
  if (error) {
    return <CardTableError error={error} />;
  }
  return (
    <div>
      <div>
        <div className="mb-5 flex justify-center">
          <ButtonRefresh onClick={handleRefresh} />
          {/* <button
            onClick={() => openModal()}
            className="inline-block px-4 py-2 bg-tertiary uppercase font-semibold"
          >
            add new project
          </button> */}
        </div>
        <div className="grid lg:md:grid-cols-3 grid-cols-1 gap-8">
          {partners.map((partner) => (
            <CardPartner key={partner.id} partner={partner} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
