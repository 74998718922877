import React, { useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { toast } from "react-toastify";

// components
import ModalConfirmation from "../Modals/ModalConfirmation";
import { useGlobalStore } from "../../store/GlobalStoreContext";

const DropdownShowcaseCard = ({
  showcase,
  googleUser,
  setViewShowcaseImg,
  openModalView,
  projects,
  setProjects,
}) => {
  const { apiUrl } = useGlobalStore();

  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const removeShowcase = () => {
    fetch(`${apiUrl}/api/v2/projects/${showcase.id}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("accessToken")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setDropdownPopoverShow(false);
          toast.success("successfully deleted showcase");
          const filteredShowcases = projects.filter(
            (item) => item.id !== showcase.id
          );
          setProjects(filteredShowcases);
        }
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <>
      <a
        className="text-white bg-secondary py-3 px-3 rounded-full absolute top-[20px] right-[20px]"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          setDropdownPopoverShow(!dropdownPopoverShow);
          setViewShowcaseImg(
            `${apiUrl}/public/uploads/images/${showcase.image}`
          );
        }}
      >
        <FaEllipsisV />
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 absolute top-0 right-0 translate-x-[-60%] translate-y-[20%]  py-2 list-none text-center rounded shadow-lg min-w-48 max-h-48 overflow-y-auto mb-2"
        }
      >
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => {
            e.preventDefault();
            openModalView();
            setDropdownPopoverShow(false);
          }}
        >
          View Project
        </a>
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => {
            e.preventDefault();
            setModalIsOpen(true);
            setDropdownPopoverShow(false);
          }}
        >
          Remove Project
        </a>
      </div>
      <ModalConfirmation
        isOpen={modalIsOpen}
        closeModal={closeModal}
        action={removeShowcase}
      />
    </>
  );
};

export default DropdownShowcaseCard;
